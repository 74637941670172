import { createApp, ref } from 'vue';

const app = createApp({
    setup() {
        const hambugerMenuShow = ref(false)
        const openHambugerMenu = () => {
            hambugerMenuShow.value = true   
        }
        const closeHamburgerMenu = () => {
            hambugerMenuShow.value = false
        }
        const toggleHamburgerMenu = () => {
            hambugerMenuShow.value = !hambugerMenuShow.value 
        }
        return {
            hambugerMenuShow, openHambugerMenu, closeHamburgerMenu, toggleHamburgerMenu
        }
    }
})
app.mount('#hamburgerMenu')