import { createApp, onMounted, ref } from 'vue';

const app = createApp({
    setup() {
        const MVShow = ref([true, false, false])
        const circleShow = ref([false, false, false])

        onMounted(() => {
            async function loop1() {
                for (let i = 1; i < MVShow.value.length; i++) {
                    await new Promise(resolve => {
                        setTimeout(() => {
                            MVShow.value = MVShow.value.fill(false)
                            MVShow.value[i] = true
                            // console.log(MVShow.value, i)
                            if (i === MVShow.value.length - 1) {
                                i = -1
                            }
                            resolve();
                        }, 5000);
                    });
                }
            }
            loop1();

            async function loop2(){
                for (let j = 0; j < circleShow.value.length; j++) {
                    await new Promise(resolve => {
                        setTimeout(() => {
                            circleShow.value[j] = true
                            // console.log(circleShow.value, j)
                            resolve();
                        }, 1000);
                    });
                }
            }
            loop2();


        })
        return {
            MVShow, circleShow
        }
    }
})
app.mount('#MVSlider')