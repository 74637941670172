const circles = [];
circles[0] = document.querySelectorAll('.u-opacity-change--1');
circles[1] = document.querySelectorAll('.u-opacity-change--2');
circles[2] = document.querySelectorAll('.u-opacity-change--3');

async function circleLoop() {
    for (let key = 0; key < circles.length; key++) {
        await new Promise(resolve => {
            setTimeout(() => {
                for (let count = 0; count < circles[key].length; count++) {
                    circles[key][count].classList.add('active')
                }
                resolve();
            }, 1000)
        });
        if (key === circles.length - 1) {
            key = -1
            await new Promise(resolve => {
                setTimeout(() => {
                    for (let i = 0; i < circles.length; i++) {
                        for (let j = 0; j < circles[i].length; j++) {
                            circles[i][j].classList.remove('active')
                        }
                    }
                    resolve();
                }, 3000)
            });
        }
    }
}
circleLoop();
