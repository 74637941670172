import { createApp, ref } from 'vue';

const app = createApp({
    setup() {
        const accordionShow = ref([false, false, false, false, false, false])

        const toggleAccordion = (number) => {
            accordionShow.value[number] = !accordionShow.value[number]
            // console.log(number + 'がクリックされました')
        }
        return {
            accordionShow, toggleAccordion
        }
    }
})
app.mount('#symptom')